<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddTimeModel"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('TIMEREC.ADD_TIMEMODEL.HEADER.TEXT')}}</p>

          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('TIMEREC.ADD_TIMEMODEL.NAME')"  class="mr-3 inputx" :placeholder="$t('TIMEREC.ADD_TIMEMODEL.PLACEHOLDER.NAME')"  v-model="data.name" :danger="name_fail" val-icon-danger="clear"/>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
              <vs-input-number v-model="data.hours_monday" :label="$t('COM.MONDAY')" class="inputx mr-3" :min="0" :max="8"/>
              <vs-input-number v-model="data.hours_tuesday" :label="$t('COM.TUESDAY')" class="inputx mr-3" :min="0" :max="8"/>
              <vs-input-number v-model="data.hours_wednesday" :label="$t('COM.WEDNESDAY')" class="inputx mr-3" :min="0" :max="8"/>
              <vs-input-number v-model="data.hours_thursday" :label="$t('COM.THURSDAY')" class="inputx mr-3" :min="0" :max="8"/>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
              <vs-input-number v-model="data.hours_friday" :label="$t('COM.FRIDAY')" class="inputx mr-3" :min="0" :max="8"/>
              <vs-input-number v-model="data.hours_saturday" :label="$t('COM.SATURDAY')" class="inputx mr-3" :min="0" :max="8"/>
              <vs-input-number v-model="data.hours_sunday" :label="$t('COM.SUNDAY')" class="inputx mr-3" :min="0" :max="8"/>
          </div>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddTimeModelDlg",
  props:[],
  components:{
    
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('TIMEREC.ADD_TIMEMODEL.TITLE'),
      name_fail:false,
      name_ok:false,
      data:this.GetEmptyData()
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('TIMEREC.ADD_TIMEMODEL.TITLE');
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();

          this.popupTitle = this.$t('TIMEREC.ADD_TIMEMODEL.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
        }
      },
      accepted(){

          this.$store.dispatch('timerec/saveTimeModel', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TIMEREC.ADD_TIMEMODEL.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('TIMEREC.ADD_TIMEMODEL.WARNING.SAVE').replace("*1*",text)}); 
            }
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.ADD_TIMEMODEL.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
          company_fk:0,
          name:"",
          hours_monday:0,
          hours_tuesday:0,
          hours_wednesday:0,
          hours_thursday:0,
          hours_friday:0,
          hours_saturday:0,
          hours_sunday:0
        };
      }
  },
  computed:{
    formValid(){
      return this.name_ok;
    },
    name(){
      return this.data.name;
    }
  },
  watch:{

    name(value)
    {
      this.name_fail = value.length == 0;
      this.name_ok = !this.name_fail;
    }
  }
};
</script>
<style>
div#promptAddTimeModel > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddTimeModel > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>