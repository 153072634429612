<template>
<div>
  <vs-row vs-justify="left">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('TIMEREC.TIMEMODEL.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('TIMEREC.TIMEMODEL.HEADER.TEXT')}}</span>
        </p>
        <AddTimeModelDlg ref="addTimeModelDlg" v-on:Success="LoadData"></AddTimeModelDlg>
      </vs-card>
    </vs-col>
  </vs-row>
  <vs-row vs-justify="left">
    <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="6" vs-sm="12" vs-xs="12">
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddTimeModel">{{$t('TIMEREC.TIMEMODEL.ADD')}}</vs-button>
            </div> 
            <div id="timemodels_table_cont"  class="vs-con-loading__container" >
            
              <vs-table
                search
                :data="timemodels"
                :noDataText="$t('TIMEREC.TIMEMODEL.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('TIMEREC.TIMEMODEL.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="name">
                    {{$t('TIMEREC.TIMEMODEL.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="hours_monday">
                    {{GetWeekDayString()}}
                  </vs-th>                                                                                                         
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>                  
                    <vs-td :data="tr.hours_monday">
                      {{GetHoursString(tr)}}
                    </vs-td>                                                                                                  
                    <vs-td>
                      <vs-button  @click="EditTimeModel(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteTimeModel(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table> 
            </div>     
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';
import AddTimeModelDlg from './components/dialogs/AddTimeModelDlg.vue';

export default {
  name: "TimeModels",
  components: {
    AddTimeModelDlg
  },
    data: function (){
      return {
      timemodels: []
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.timerec.timemodels.data != null)
      {
        data = this.$store.state.timerec.timemodels.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.timerec.timemodels.status) != 'undefined')
        loading = this.$store.state.timerec.timemodels.status.loading;
      return loading;
    },
  },
  watch: {
      getTableData(value) {
        this.timemodels = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#timemodels_table_cont'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#timemodels_table_cont'});
        }
      },
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      ...codeHelper,
      LoadData: function ()
      {
          this.$store.dispatch('timerec/getTimeModels', { root: true },{ root: true }); 
      },
      EditTimeModel(data){
        this.$refs.addTimeModelDlg.InitDlgData(data);
      },
      AddTimeModel(){
        this.$refs.addTimeModelDlg.ShowDlg();
      },
      GetActYear(){
        const d = new Date();
        return d.getFullYear();
      },
      OpenConfirmDeleteTimeModel(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('TIMEREC.TIME_MODEL.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name":name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('timerec/deleteTimeModel', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TIMEREC.TIME_MODEL.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('TIMEREC.TIME_MODEL.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.TIME_MODEL.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      GetHoursString(data){
        var retVal = "";
        if(this.IsNotNull(data))
        {
          retVal = data.hours_monday + ' | ' + data.hours_tuesday + ' | ' + data.hours_wednesday + ' | ' + data.hours_thursday + ' | ' + data.hours_friday + ' | ' + data.hours_saturday + ' | ' + data.hours_sunday;
        }
        return retVal
      },
      GetWeekDayString(){

        return this.$t('COM.MONDAY.SHORT') + ' | ' + this.$t('COM.TUESDAY.SHORT') + ' | ' + this.$t('COM.WEDNESDAY.SHORT') + ' | '  + 
        this.$t('COM.THURSDAY.SHORT') + ' | ' + this.$t('COM.FRIDAY.SHORT') + ' | ' + this.$t('COM.SATURDAY.SHORT') + ' | ' + this.$t('COM.SUNDAY.SHORT');
      }
  }
};

</script>